<template>
  <div
    v-editable="blok"
    class="image-and-text"
    :class="[
      blok.flip_content && 'image-and-text--flipped',
      blok.thumbnail &&
        blok.thumbnail.filename &&
        'image-and-text--has-thumbnail',
    ]"
  >
    <div class="image-and-text__layout">
      <div class="image-and-text__content">
        <Picture
          v-if="blok.thumbnail && blok.thumbnail.filename"
          v-editable="blok"
          class="image-and-text__content-thumbnail"
          :blok="makePicture(blok.thumbnail)"
          loading="lazy"
        />
        <div class="image-and-text__content-text">
          <CustomRTE :blok="blok.text[0]" class="image-and-text__text" />
          <CTAGroup
            v-if="blok.links && blok.links.length > 0"
            :ctas="blok.links"
            class="image-and-text__links"
          />
        </div>
      </div>
      <div class="image-and-text__image">
        <Picture
          :width="780"
          :blok="blok.layered_image[0]"
          :flipradius="!blok.flip_content"
          :thumbnail="makeThumbnail(blok.layered_image[0]).thumbnail"
          :thumbnailposition="
            makeThumbnail(blok.layered_image[0]).thumbnailposition
          "
          loading="lazy"
        />
      </div>
    </div>
  </div>
</template>

<script lang="ts" setup>
import {
  ImageAndTextBlokProps,
  ImageBlokProps,
  LayeredPictureBlokProps,
} from '@/types';

// Props
defineProps<{
  blok: ImageAndTextBlokProps;
}>();

const makePicture = (src: ImageBlokProps) => ({
  image: src,
});

const makeThumbnail = (src: LayeredPictureBlokProps) => ({
  thumbnail:
    src.thumbnail && src.thumbnail.filename ? src.thumbnail : undefined,
  thumbnailposition: src.thumbnail_position
    ? src.thumbnail_position
    : undefined,
});
</script>

<style lang="scss">
.image-and-text {
  @include container;

  &__layout {
    column-gap: columns(2);
    display: grid;
  }

  &__content {
    margin-block-start: var(--spacing-s);
    width: 100%;

    .picture {
      display: none;
    }
  }

  &__image {
    width: 100%;
  }

  &__links {
    margin-block-start: var(--spacing-s);
  }

  @media all and #{$mq-s-max} {
    .image-and-text__content {
      order: 2;
    }
  }

  .green {
    color: var(--bright-green);
  }
}

@media all and #{$mq-m-min} {
  .image-and-text {
    padding-inline-end: 0;
    padding-inline-start: 0;

    &__layout {
      align-items: center;
      grid-template-columns: columns(7) columns(13);
      margin-inline-start: columns(2);
    }

    &__content {
      margin-block-start: 0;
    }

    &--flipped {
      .image-and-text__content {
        order: 1;
      }

      .image-and-text__layout {
        grid-template-columns: columns(13) columns(7);
        margin-inline-start: 0;
      }
    }
  }
}

@media all and #{$mq-l-min} {
  .image-and-text {
    &__content {
      position: relative;
    }

    &--has-thumbnail {
      --thumbnail-height: 320px;
      --thumbnail-width: 240px;

      padding-block-start: clamp(
        var(--spacing-xxl),
        22.5%,
        var(--thumbnail-height)
      );

      .image-and-text__content {
        display: grid;
        grid-template-rows: 120px 1fr 120px;
        height: 100%;

        .picture {
          align-self: flex-end;
          display: block;
          grid-row: 1;
          margin-inline-end: columns(3);
        }

        picture {
          max-height: var(--thumbnail-height);
          max-width: var(--thumbnail-width);
        }
      }

      .image-and-text__content-text {
        align-self: center;
        grid-row: 2;
        padding-block: var(--spacing-l);
      }
    }

    &--flipped {
      .image-and-text__content {
        .picture {
          margin-inline: columns(3) 0;
        }
      }
    }
  }
}
</style>
